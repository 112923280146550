const bootstrap = require('bootstrap');
import 'bootstrap/dist/css/bootstrap.min.css';
const disegniListRaw = require('./disegni.json');
const disegniList = disegniListRaw.map((el, index) => { let o = Object.assign({}, el); o.order = index; return o; });
disegniList.sort((a, b) => a.order - b.order);
const nImages = disegniList.length;
const slider = false;
if (slider) {
    var showing = 1;
    const firstItemID = String(disegniList[0].order);
    const loadedImagesIds = [];
    const img_container = document.querySelector('.img_container');
    const img_info_container = document.querySelector('.img_info');
    const promiseImage = (item, index) => {
        return new Promise((resolve, reject) => {
            let path = 'imgs/fixed/' + item.filename;
            const image = new Image();
            image.onload = () => {
                image.classList.add('im3d');
                let id = String(item.order);
                image.setAttribute("id", id);
                loadedImagesIds.push(item.order);
                img_container.append(image);
                resolve({ path, status: 'success' });
            };
            image.onerror = () => resolve({ path, status: 'error' });
            image.src = path;
        });
    };
    function preLoadImages() {
        return Promise.all(disegniList.map(function (item, index) { return promiseImage(item, index); }));
    }
    const navButtons = function () {
        let prev_button = document.querySelector('prev');
        let next_button = document.querySelector('next');
        let next = document.createElement('div');
        next.innerHTML = ">";
        next.classList.add('next');
        next.classList.add('img_nav');
        next.setAttribute('title', 'Next image');
        img_container.append(next);
        let prev = document.createElement('div');
        prev.innerHTML = "<";
        prev.classList.add('prev');
        prev.classList.add('img_nav');
        prev.setAttribute('title', 'Previous image');
        img_container.append(prev);
    };
    const showImage = function (image) {
        let images = document.getElementsByClassName('im3d');
        Array.from(images).forEach((img) => {
            img.classList.remove('show');
        });
        image.classList.add('show');
    };
    const shiftImage = function (direction) {
        let currentElement = document.querySelector('.show');
        console.log(currentElement);
        let nextItemID = firstItemID;
        if (currentElement) {
            let id = currentElement.getAttribute('id');
            let index = loadedImagesIds.indexOf(parseInt(id));
            console.log(index + " " + (loadedImagesIds.length - 1));
            if (index == (loadedImagesIds.length - 1) && direction == "next") {
                index = -1;
            }
            else if (index == 0 && direction == "prev") {
                index = loadedImagesIds.length;
            }
            console.log(index);
            console.log('nextItemID ' + nextItemID);
            nextItemID = (direction == "next") ? String(loadedImagesIds[index + 1]) : String(loadedImagesIds[index - 1]);
            if (!nextItemID)
                nextItemID = firstItemID;
            console.log('nextItemID ' + nextItemID);
            let nextElement = document.getElementById(nextItemID);
            let disegno = disegniList.find((item) => { return String(item.order) === nextItemID; });
            showDisegnoData(disegno);
            if (direction == "next") {
                currentElement.classList.remove('slideR2C');
                setTimeout(() => currentElement.classList.add('slideC2T'), 1);
                nextElement.classList.add('show');
                setTimeout(() => nextElement.classList.add('slideR2C'), 10);
                setTimeout(() => currentElement.classList.remove('show'), 900);
                setTimeout(() => currentElement.classList.remove('slideC2T'), 950);
            }
            else {
                currentElement.classList.remove('slideL2C');
                setTimeout(() => currentElement.classList.add('slideC2B'), 1);
                nextElement.classList.add('show');
                setTimeout(() => nextElement.classList.add('slideL2C'), 10);
                setTimeout(() => currentElement.classList.remove('show'), 900);
                setTimeout(() => currentElement.classList.remove('slideC2B'), 950);
            }
        }
        return nextItemID;
    };
    const showDisegnoData = (disegno) => {
        let html = "";
        img_info_container.classList.add('fadeOut');
        setTimeout(() => img_info_container.classList.remove('fadeOut'), 800);
        img_info_container.classList.add('fadeIn');
        if (disegno) {
            html = "<h5 style='font-size: 2rem;'>" + disegno.titolo + "</h5>";
            html += "<div class='my-2'>" + disegno.sw + " " + disegno.ver + "</div>";
            html += "<div style='font-size: 1.5rem;'>" + disegno.year + "</div>";
        }
        setTimeout(() => img_info_container.innerHTML = html, 400);
        return html;
    };
    const showNavs = () => {
        let img_nav = document.querySelectorAll(".img_nav");
        img_nav.forEach(el => {
            el.classList.add("block");
        });
    };
    preLoadImages().
        then((values) => {
        loadedImagesIds.sort((a, b) => a - b);
        let loader_container = document.querySelector(".loader_container");
        loader_container === null || loader_container === void 0 ? void 0 : loader_container.remove();
        let disegno = disegniList.find((item) => { return String(item.order) === firstItemID; });
        let image = document.getElementById(String(disegno.order));
        showDisegnoData(disegno);
        showImage(image);
        showNavs();
    }).
        catch((values) => {
        console.log('error');
    });
    navButtons();
    document.addEventListener("click", (event) => {
        let element = event.target;
        if (element.classList.contains("next")) {
            shiftImage('next');
        }
    });
    document.addEventListener("click", (event) => {
        let element = event.target;
        if (element.classList.contains("prev")) {
            shiftImage('prev');
        }
    });
}
const imageGallery = document.getElementById('imageGallery');
let nImgLoaded = 0;
function loadBatchImages(batch) {
    let i = nImgLoaded;
    while (i < (batch + nImgLoaded) && i < (nImages - 1)) {
        let item = disegniList[i];
        let path_thumb = 'imgs/fixed/' + item.filename;
        let path_src = 'imgs/' + item.filename;
        const foto = document.createElement('div');
        foto.classList.add('col');
        const foto_home = document.createElement('div');
        foto_home.classList.add('foto_home');
        foto_home.setAttribute('data-thumb', path_thumb);
        foto_home.setAttribute('data-src', path_src);
        const image = new Image();
        image.onload = () => {
            image.classList.add('im3d');
            let id = String(item.order);
            image.setAttribute("id", id);
        };
        image.src = path_thumb;
        foto_home.append(image);
        foto.append(foto_home);
        imageGallery.append(foto);
        setTimeout(() => image.classList.add("animate"), 100);
        i++;
    }
    nImgLoaded = i;
    bindShowFullscreen();
}
const mEl = document.getElementById('staticBackdrop');
const mElo = new bootstrap.Modal(mEl);
function showAsModal(id, src) {
    let disegno = disegniList.find((item) => { return String(item.order) === id; });
    let mbody = mEl.querySelector(".modal-body");
    let image = new Image();
    image.onload = () => {
        image.classList.add('full-screen-img');
    };
    let mtitle = mEl.querySelector(".modal-title");
    let mfooter = mEl.querySelector(".footer-text");
    image.src = src;
    mtitle.innerHTML = disegno.titolo;
    mbody.innerHTML = "";
    mbody.append(image);
    mfooter.innerHTML = `${disegno.sw} ${disegno.ver} - ${disegno.year}`;
    mElo.show();
    mEl.addEventListener('hidden.bs.modal', function (event) {
        const els = document.querySelectorAll(".modal-backdrop");
        els.forEach(el => {
            if (el instanceof HTMLImageElement) {
                el.remove();
            }
        });
    });
}
const bindShowFullscreen = function () {
    const boxes = document.querySelectorAll('.foto_home');
    boxes.forEach(box => {
        box.addEventListener('click', function (event) {
            var _a;
            if (event.target instanceof HTMLImageElement) {
                let src = (_a = event.target.parentElement) === null || _a === void 0 ? void 0 : _a.dataset.src;
                let id = event.target.id;
                showAsModal(id, src);
            }
        });
    });
};
loadBatchImages(12);
window.addEventListener('scroll', () => {
    if (window.scrollY + window.innerHeight >=
        document.documentElement.scrollHeight) {
        loadBatchImages(12);
    }
});
